<template>
  <v-card class="rounded-lg my-5">
    <div v-if="!active">
      <v-card-title>{{ info.name }}</v-card-title>
      <v-row class="mt-2 mx-4" v-for="(field, index) of info.data" :key="index">
        <v-col cols="6">{{ field.label }} :</v-col>
        <v-col cols="6" :class="field.value ? '' : 'text--disabled'">
          {{ field.value || "Not set" }}
        </v-col>
        <v-divider v-if="!active" class="mx-4"></v-divider>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="my-3 px-3 mx-4"
          rounded
          dark
          color="info"
          @click="active = !active"
        >
          Edit
        </v-btn>
      </v-card-actions>
    </div>
    <v-form :ref="`form_${info.type}`" @submit.prevent="submitForm" v-else>
      <v-card-title><v-icon>mdi-pencil</v-icon> {{ info.name }}</v-card-title>
      <v-row
        class="mt-2 mx-4"
        v-for="(field, index) of info.editData"
        :key="index"
      >
        <div v-if="field.prefix" class="text--secondary mt-2">
          <v-divider class="mb-2"></v-divider>
          {{ field.prefix }}
        </div>
        <v-col cols="6">{{ field.label }} :</v-col>
        <v-col cols="6">
          <v-text-field
            hide-details="auto"
            class="pt-0"
            dense
            outlined
            :placeholder="field.label"
            :value="field.value"
            @input="field.onInput(field.key, $event)"
            :type="field.type || 'text'"
            :rules="field.rules"
          ></v-text-field>
        </v-col>
        <v-divider v-if="!active" class="mx-4"></v-divider>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="my-3 px-3"
          type="button"
          rounded
          text
          @click="cancelAction"
        >
          Cancel
        </v-btn>
        <v-btn
          class="my-3 px-3 success"
          type="submit"
          :disabled="loading"
          :loading="loading"
          rounded
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
export default {
  name: "ProfileInfo",
  props: {
    info: Object,
  },
  data: () => {
    return {
      active: false,
      loading: false,
    };
  },
  methods: {
    async submitForm() {
      if (this.$refs[`form_${this.info.type}`].validate()) {
        this.loading = true;
        const result = await this.info.action(this.info.type);
        this.loading = false;
        if (result) this.active = false;
      }
    },
    cancelAction() {
      this.active = false;
      this.$store.dispatch("authModule/getProfile");
    },
  },
};
</script>

<style></style>
